import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ModalFaqPageModule } from 'src/app/pages/client/modal/modal-faq/modal-faq.module';
import { ModalFilePageModule } from 'src/app/pages/client/modal/modal-file/modal-file.module';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: 'src/app/pages/client/dashboard/dashboard.module#DashboardPageModule'
  },
  {
    path: 'faq',
    loadChildren: 'src/app/pages/client/faq/faq.module#FaqPageModule'
  },
  {
    path: 'novo-produto',
    loadChildren: () => import('src/app/pages/client/novo-produto/novo-produto.module').then(m => m.NovoProdutoPageModule)
  },
  {
    path: 'produtos',
    loadChildren: () => import('src/app/pages/client/produtos/produtos.module').then(m => m.ProdutosPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ModalFaqPageModule,
    ModalFilePageModule,
  ],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
