import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.authentication();
      this.authenticationAdministrator();
      this.authenticationAgent();
      this.authenticationClient();
    });
  }

  async authentication() {
    this.authenticationService.authenticationState.subscribe(async state => {
      console.log('login: ' + state);
      if (state) {
        this.router.navigate(['auth']);
      }
    });
  }

  async authenticationAdministrator() {
    this.authenticationService.authenticationStateAdministrator.subscribe(async state => {
      console.log('administrator: ' + state);
      if (state) {
        this.setTimeout();
        this.router.navigate(['administrator', 'dashboard']);
      }
    });
  }

  async authenticationAgent() {
    this.authenticationService.authenticationStateAgent.subscribe(async state => {
      console.log('agent:' + state);
      if (state) {
        this.setTimeout();
        this.router.navigate(['agent', 'dashboard']);
      }
    });
  }

  async authenticationClient() {
    this.authenticationService.authenticationStateClient.subscribe(async state => {
      console.log('client:' + state);
      if (state) {
        this.setTimeout();
        this.router.navigate(['client', 'dashboard']);
      }
    });
  }

  setTimeout() {
    setTimeout(() => {
      this.authenticationService.logout()
    }, JSON.parse(sessionStorage.getItem('EXPIRES_IN')));
  }
}
