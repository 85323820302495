import { Component, ViewChild } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { ImagesService } from 'src/app/services/images/images.service';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-modal-file',
  templateUrl: './modal-file.page.html',
  styleUrls: ['./modal-file.page.scss'],
})
export class ModalFilePage {
  @ViewChild('formNewImg', { static: false }) formNewImg: NgForm;
  @ViewChild('formOldImg', { static: false }) formOldImg: NgForm;

  init = false;
  skeleton = ['0', '1', '2', '3', '4', '5'];
  newImg: any;
  oldImg: any;
  initImagens: any;
  imagens: any;
  size_col: any;

  constructor(
    public navParams: NavParams,
    public modalController: ModalController,
    private img: ImagesService,
  ) { }

  ionViewWillEnter() {
    this.responsive();
    setTimeout(() => {
      this.init = true;
      this.img.getImage().subscribe((res) => {
        this.initImagens = res.imagens
        this.imagens = res.imagens
      }, error => {
      });
    }, 700);
  }

  responsive() {
    if ($(window).width() <= 768) {
      this.size_col = '6';
    } else if ($(window).width() > 768 && $(window).width() < 1024) {
      this.size_col = '4';
    } else {
      this.size_col = '3';
    }
  }

  async openImg(src) {
    this.img.openViewer(src);
  }

  async filterImages(evt) {
    const searchTerm = evt.srcElement.value;
    if (evt.srcElement.value === undefined || evt.srcElement.value === '' || evt.srcElement.value === null) {
      this.imagens = await this.initImagens;
    }
    else {
      this.imagens = await this.initImagens;
      this.imagens = this.imagens.filter(image => {
        return (image.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
      });
    }
  }

  async close() {
    await this.modalController.dismiss('cancel');
  }

  changeFile(event) {
    // if (event.target.files.length > 0) {
    //   const file = event.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsArrayBuffer(file);
    //   reader.onload = () => {
    //     // get the blob of the image:
    //     let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))], { type: 'image/jpeg' });
    //     this.blob = blob;
    //     console.log(this.blob)
    //     // create blobURL, such that we could use it in an image element:
    //     this.file = String(URL.createObjectURL(blob));
    //   };
    //   reader.onerror = (error) => {
    //   };
    // }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.newImg = reader.result
        this.oldImg = '';
        $('.iconProd').removeClass('iconSeleted')
        $('.cardProd').removeClass('selected');
      };
      reader.onerror = (error) => {
      };
    }
    else {
      this.newImg = '';
      this.oldImg = '';
      $('.iconProd').removeClass('iconSeleted')
      $('.cardProd').removeClass('selected');
    }
  }

  select(img) {
    this.formNewImg.reset();
    this.newImg = '';
    $('.iconProd').removeClass('iconSeleted')
    $('.cardProd').removeClass('selected');
    setTimeout(() => {
      $(`#${img.id}`).addClass('selected');
      $(`#i${img.id}`).addClass('iconSeleted');
      this.oldImg = img;
    }, 100);
  }

  async save() {
    if (this.newImg) {
      await this.modalController.dismiss({
        type: 'new',
        imagem: this.newImg,
      });
    }

    if (this.oldImg) {
      await this.modalController.dismiss({
        type: 'old',
        id: this.oldImg.id,
        name: this.oldImg.name,
        imagem: this.oldImg.imagem,
      });
    }
  }

}
