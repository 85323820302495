import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AuthAdministratorGuard implements CanActivate {

  constructor(
    public auth: AuthenticationService,
  ) { }

  canActivate(): boolean {
    return this.auth.isAdministratorAuthenticated();
  }

}
