import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { AuthAdministratorGuard } from './guards/auth-administrator/auth-administrator.guard';
import { AuthAgentGuard } from './guards/auth-agent/auth-agent.guard';
import { AuthClientGuard } from './guards/auth-client/auth-client.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: './pages/public/auth/auth.module#AuthPageModule'
  },

  {
    path: 'administrator',
    canActivate: [AuthAdministratorGuard],
    loadChildren: './modules/administrator/administrator-routing.module#AdministratorRoutingModule'
  },
  {
    path: 'agent',
    canActivate: [AuthAgentGuard],
    loadChildren: './modules/agent/agent-routing.module#AgentRoutingModule'
  },
  {
    path: 'client',
    canActivate: [AuthClientGuard],
    loadChildren: './modules/client/client-routing.module#ClientRoutingModule'
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
