import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { IonicStorageModule } from '@ionic/storage';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AdministratorRoutingModule } from './modules/administrator/administrator-routing.module';
import { AgentRoutingModule } from './modules/agent/agent-routing.module';
import { ClientRoutingModule } from './modules/client/client-routing.module';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      swipeBackEnabled: false,
      hardwareBackButton: false,
    }),
    IonicStorageModule.forRoot(),
    RecaptchaModule.forRoot(),
    AppRoutingModule,
    AdministratorRoutingModule,
    AgentRoutingModule,
    ClientRoutingModule,
    CommonModule,
    HttpClientModule,
    FormsModule,    
    NgxCurrencyModule,
    NgxIonicImageViewerModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
    // { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
