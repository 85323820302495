import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ModalFilePageModule } from 'src/app/pages/administrator/modal/modal-file/modal-file.module';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: 'src/app/pages/administrator/dashboard/dashboard.module#DashboardPageModule'
  },
  {
    path: 'novo-usuario',
    loadChildren: () => import('src/app/pages/administrator/novo-usuario/novo-usuario.module').then(m => m.NovoUsuarioPageModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('src/app/pages/administrator/usuarios/usuarios.module').then(m => m.UsuariosPageModule)
  },
  {
    path: 'categorias',
    loadChildren: () => import('src/app/pages/administrator/categorias/categorias.module').then(m => m.CategoriasPageModule)
  },
  {
    path: 'unidades',
    loadChildren: () => import('src/app/pages/administrator/unidades/unidades.module').then(m => m.UnidadesPageModule)
  },
  {
    path: 'produtos',
    loadChildren: () => import('src/app/pages/administrator/produtos/produtos.module').then(m => m.ProdutosPageModule)
  },
  {
    path: 'novo-produto',
    loadChildren: () => import('src/app/pages/administrator/novo-produto/novo-produto.module').then(m => m.NovoProdutoPageModule)
  },
  {
    path: 'novo-encarte',
    loadChildren: () => import('src/app/pages/administrator/novo-encarte/novo-encarte.module').then(m => m.NovoEncartePageModule)
  },
  {
    path: 'encartes',
    loadChildren: () => import('src/app/pages/administrator/encartes/encartes.module').then(m => m.EncartesPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ModalFilePageModule,
  ],
  exports: [RouterModule]
})
export class AdministratorRoutingModule { }
