import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from 'src/app/auth/user';
import { AuthResponse } from 'src/app/auth/auth-response';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // AUTH_SERVER_ADDRESS = 'http://localhost:3000';
  // AUTH_SERVER_ADDRESS = 'http://172.168.0.104:3000';
  AUTH_SERVER_ADDRESS = 'https://api.encartes.groupmy.com.br:3001'

  authenticationState = new BehaviorSubject(true);
  authenticationStateAdministrator = new BehaviorSubject(false);
  authenticationStateAgent = new BehaviorSubject(false);
  authenticationStateClient = new BehaviorSubject(false);

  constructor(
    private storage: Storage,
    private plt: Platform,
    private httpClient: HttpClient,
  ) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    // this.storage.get(TOKEN_KEY).then(res => {
    // this.storage.get('ACCESS_TOKEN').then(res => {
    //   if (res) {
    //     this.authenticationState.next(true);
    //   }
    // });
    if (sessionStorage.getItem('ACCESS_TOKEN')) {
      // this.authenticationState.next(true);
      let user = JSON.parse(sessionStorage.getItem('USER'))
      switch (user.type) {
        case 'ADMINISTRATOR':
          this.authenticationStateAdministrator.next(true);
          this.authenticationState.next(false);
          break;
        case 'AGENT':
          this.authenticationStateAgent.next(true);
          this.authenticationState.next(false);
          break;
        case 'CLIENT':
          this.authenticationStateClient.next(true);
          this.authenticationState.next(false);
          break;
        default:
          this.authenticationStateAdministrator.next(false);
          this.authenticationStateAgent.next(false);
          this.authenticationStateClient.next(false);
          this.authenticationState.next(true);
      }
    }
  }

  login(user: User): Observable<AuthResponse> {
    return this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/login`, user).pipe(
      tap(async (res: AuthResponse) => {


        if (res.user) {
          // await this.storage.set('ACCESS_TOKEN', JSON.stringify(res.access_token));
          await sessionStorage.setItem('USER', JSON.stringify(res.user));
          await sessionStorage.setItem('ACCESS_TOKEN', JSON.stringify(res.access_token));
          await sessionStorage.setItem('EXPIRES_IN', JSON.stringify(res.expires_in));
          // this.authenticationState.next(true);
          switch (res.user.type) {
            case 'ADMINISTRATOR':
              this.authenticationStateAdministrator.next(true);
              this.authenticationState.next(false);
              break;
            case 'AGENT':
              this.authenticationStateAgent.next(true);
              this.authenticationState.next(false);
              break;
            case 'CLIENT':
              this.authenticationStateClient.next(true);
              this.authenticationState.next(false);
              break;
            default:
              this.authenticationStateAdministrator.next(false);
              this.authenticationStateAgent.next(false);
              this.authenticationStateClient.next(false);
              this.authenticationState.next(true);
          }


        }
      })
    );
  }

  logout() {
    // return this.storage.remove('ACCESS_TOKEN').then(() => {
    //   this.storage.remove('EXPIRES_IN').then(() => {
    //     this.authenticationState.next(false);
    //   });
    // });
    sessionStorage.removeItem('ACCESS_TOKEN');
    sessionStorage.removeItem('EXPIRES_IN');
    sessionStorage.removeItem('USER');
    this.authenticationState.next(true);
    this.authenticationStateAdministrator.next(false);
    this.authenticationStateAgent.next(false);
    this.authenticationStateClient.next(false);
  }

  isAuthenticated() {
    // return this.authenticationState.asObservable();
    return this.authenticationState.value;
  }

  isAdministratorAuthenticated() {
    return this.authenticationStateAdministrator.value;
  }

  isAgentAuthenticated() {
    return this.authenticationStateAgent.value;
  }

  isClientAuthenticated() {
    return this.authenticationStateClient.value;
  }
}
