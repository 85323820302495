import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-faq',
  templateUrl: './modal-faq.page.html',
  styleUrls: ['./modal-faq.page.scss'],
})
export class ModalFaqPage implements OnInit {

  question: any;
  init = false;
  skeleton = ['0', '1', '2', '3', '4', '5'];

  constructor(
    public navParams: NavParams,
    public modalController: ModalController,
  ) { }

  ngOnInit() {
    this.question = this.navParams.get('question');
    setTimeout(() => {
      this.init = true;
    }, 1500);
  }

  async close() {
    await this.modalController.dismiss('cancel');
  }

}
