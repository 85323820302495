import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalFilePageRoutingModule } from './modal-file-routing.module';

import { ModalFilePage } from './modal-file.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalFilePageRoutingModule
  ],
  declarations: [ModalFilePage]
})
export class ModalFilePageModule {}
