import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ViewerModalComponent } from 'ngx-ionic-image-viewer';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  // AUTH_SERVER_ADDRESS = 'http://localhost:3000';
  // AUTH_SERVER_ADDRESS = 'http://api.encartes.groupmy.com.br:3000'
  AUTH_SERVER_ADDRESS = 'https://api.encartes.groupmy.com.br:3001';

  constructor(
    private httpClient: HttpClient,
    public modalController: ModalController,
  ) { }

  createImage(img): Observable<any> {
    return this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/image`, img).pipe(
      tap(async (res: any) => {
      })

    );
  }

  getImage(): Observable<any> {
    return this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/image`).pipe(
      tap(async (res: any) => {
      })

    );
  }

  deleteImage(id): Observable<any> {
    return this.httpClient.delete(`${this.AUTH_SERVER_ADDRESS}/image/${id}`).pipe(
      tap(async (res: any) => {
      })

    );
  }

  async openViewer(src) {
    const modal = await this.modalController.create({
      component: ViewerModalComponent,
      componentProps: {
        src: src
      },
      cssClass: 'ion-img-viewer',
      keyboardClose: true,
      showBackdrop: true,
      backdropDismiss: true,
    });

    return await modal.present();
  }
}
